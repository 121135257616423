import { Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import About from "../pages/about";
import Services from "../pages/services";
import Details from "../pages/services/Details";

const Routers = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/legal-services" element={<Services />} />
        <Route path="/legal-services/:id" element={<Details />} />
      </Routes>
    </>
  );
};

export default Routers;
