import React, { useContext, useRef, useState } from "react";
import { FiPhoneCall } from "react-icons/fi";
import { RiMessage3Fill } from "react-icons/ri";
import { BsSendFill } from "react-icons/bs";
import { IoMailOpenOutline } from "react-icons/io5";
import { MdContacts } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import Button from "../../components/ui/Button";
import { ContactContext } from "../../store/context/ContactContext";
import Loading from "../../assets/svg/Loading";

export default function ContactFormSection() {
    const { formData, handleChange } = useContactFormSection();
    const { handleSubmitContactForm, loading } = useContext(ContactContext);
    const formRef = useRef<HTMLFormElement>(null as unknown as HTMLFormElement);

    return (
        <div id="contact-us" className="">
            <div className="grid grid-cols-1 md:grid-cols-2">
                {/*//*__________________________________GET IN TOUCH_________________________________ */}
                <div className="bg-jet p-4 text-light min-h-[400px] flex flex-col justify-center">
                    <div className="text-2xl flex font-extrabold items-center gap-2">
                        <p>How can we help?</p> <MdContacts />
                    </div>
                    <div className="text-base flex items-center gap-2">
                        <p>
                            To find out more about our services, please contact
                            us on:
                        </p>
                    </div>
                    <div className="m-auto text-lg font-medium md:font-semibold">
                        <section className="max-w-lg mb-10">
                            <a
                                className="flex items-center gap-2 hover:text-gold"
                                href="mailto:clientservices@giambronepartnerslawltd.co.uk"
                            >
                                <IoMailOpenOutline size={29} />
                                <p className="breaker">
                                    clientservices@giambronepartnerslawltd.co.uk
                                </p>
                            </a>
                        </section>
                        <section className="max-w-lg mb-10">
                            <a
                                className="flex items-center gap-2 hover:text-gold"
                                href="tel:+448635421349"
                            >
                                <FiPhoneCall size={22} />
                                <p className="breaker">+44 863 542-1349</p>
                            </a>
                        </section>
                        <section className="max-w-lg mb-10">
                            <a
                                className="flex items-center gap-2 hover:text-gold"
                                href="/#"
                            >
                                <ImLocation2 size={22} />
                                <div className="breaker md:hidden">
                                    <p className="breaker">
                                        33 Kirby St, London EC1N 8TS, UK,
                                    </p>
                                    {/* <p className="breaker">
                                        246-250 Romford Road,
                                    </p>
                                    <p className="breaker">
                                        London, E7 9HZ, United Kingdom
                                    </p> */}
                                </div>
                                <div className="breaker hidden md:block">
                                    <p className="breaker">
                                        33 Kirby St, London EC1N 8TS, UK
                                    </p>
                                    {/* <p className="breaker">
                                        London, E7 9HZ, United Kingdom
                                    </p> */}
                                </div>
                            </a>
                        </section>
                    </div>
                </div>
                {/* //*_______________________________________________________________FORM AREA______________________________________________________ */}
                <div className="p-6">
                    <div className="text-2xl font-bold mb-4 flex items-center gap-2">
                        <p>Contact Us</p> <RiMessage3Fill />
                    </div>
                    <form
                        ref={formRef}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmitContactForm(formData, formRef?.current);
                        }}
                        className="flex flex-col w-full gap-3 max-w-md md:ml-9"
                    >
                        <div className="flex flex-col w-full gap-2">
                            <label htmlFor="name">Full Name</label>
                            <input
                                type="text"
                                name="name"
                                required
                                value={formData.name}
                                onChange={handleChange}
                                title="Please enter your name to send us a message"
                                className="p-2 rounded-md border border-gold outline-none"
                                placeholder="Enter Your Full Name"
                                id="name"
                            />
                        </div>
                        <div className="flex flex-col w-full gap-2">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                name="email"
                                required
                                value={formData.email}
                                onChange={handleChange}
                                title="We need your email address to send us a message"
                                className="p-2 rounded-md border border-gold outline-none"
                                placeholder="Enter Your Email Address"
                                id="email"
                            />
                        </div>
                        <div className="flex flex-col w-full gap-2">
                            <label htmlFor="number">Number</label>
                            <input
                                type="tel"
                                name="number"
                                required
                                value={formData.number}
                                onChange={handleChange}
                                title="Please enter your number so we can contact you"
                                className="p-2 rounded-md border border-gold outline-none"
                                placeholder="Enter Your Phone Number"
                                id="number"
                            />
                        </div>
                        <div className="flex flex-col w-full gap-2">
                            <label htmlFor="email">Message</label>
                            <textarea
                                name="message"
                                required
                                onChange={handleChange}
                                value={formData.message}
                                title="Write a message to us"
                                className="p-2 rounded-md border border-gold h-20 resize-none outline-none"
                                placeholder="Leave us a message..."
                                id="message"
                            />
                        </div>
                        <Button
                            disabled={loading}
                            className={`max-w-[200px] mt-2 px-10 py-3 text-sm ${
                                loading &&
                                "disabled:opacity-70 pointer-events-none"
                            }`}
                        >
                            {loading ? (
                                <Loading />
                            ) : (
                                <>
                                    Send{" "}
                                    <BsSendFill className="ml-1" size={15} />
                                </>
                            )}
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
}

// //?_____________________________________CONTACT FORM SECTION HOOKS_______________________________________*// //
// //?_____________________________________CONTACT FORM SECTION HOOKS_______________________________________*// //
const useContactFormSection = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        number: "",
        message: "",
    });

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return { formData, handleChange };
};
