import React, { useEffect, useState } from "react";
// import { IoMailOpenOutline } from "react-icons/io5";
// import { FiPhoneCall } from "react-icons/fi";
import { FaFacebook, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { CgClose, CgMenuRight } from "react-icons/cg";
import { Link } from "react-router-dom";
import { HiArrowLongRight } from "react-icons/hi2";
import LinkButton from "../../components/ui/LinkButton";
import logo from "../../assets/images/giambronelogo.png";
import "../../styles/components/Navbar.css";

export default function Navbar() {
    const { navClassName, handleToggleNavOpen } = useNavbar();
    return (
        <header className="sticky top-0 z-40 shadow-sm">
            {/* //*________________LABEL______________ */}
            {/* <div className="bg-gradient-to-r from-jet to-dark border-b-4 border-gold text-light p-2">
        <div className="wrapper">
          <div className="flex justify-between">
            <div className="flex md:gap-8 gap-2 items-center  flex-wrap">
              <a
                className="flex items-center hover:text-red"
                href="mailto:abdulemcmillian@mail.com"
              >
                <IoMailOpenOutline className="mx-2 text-lg" />
                abdulemcmillian@mail.com
              </a>
              <a
                className="flex items-center hover:text-red"
                href="tel:+448635421349"
              >
                <FiPhoneCall className="mx-2 text-lg" />
                +448635421349
              </a>
            </div>
            <div className="md:flex gap-4 text-xl items-center hidden">
              <a
                className="text-red hover:text-[#3b5998]"
                href="https://www.facebook.com/abdul.mcmillian"
              >
                <FaFacebook />
              </a>
              <a
                className="text-red hover:text-[#00acee]"
                href="tel:+448635421349"
              >
                <FaTwitter />
              </a>
              <a
                className="text-red hover:text-[#0e76a8]"
                href="tel:+448635421349"
              >
                <FaLinkedinIn />
              </a>
              <a
                className="text-red text-2xl hover:text-[#d62976]"
                href="tel:+448635421349"
              >
                <AiFillInstagram />
              </a>
            </div>
          </div>
        </div>
      </div> */}
            {/* //*________________NAVBAR______________ */}
            <nav className="bg-light">
                <div className="wrapper py-2 flex text-dark items-center justify-between">
                    <h1>
                        <Link className="text-2xl font-sans font-bold" to="/">
                            <img
                                src={logo}
                                className="w-[10.5rem]"
                                alt="giambrone partners logo"
                            />
                        </Link>
                    </h1>
                    <div>
                        <ul className="hidden md:flex items-center justify-between gap-8">
                            <li>
                                <Link className="hover:text-gold" to="/">
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link className="hover:text-gold" to="/about">
                                    About Us
                                </Link>
                            </li>
                            <li>
                                {/* <Link className="hover:text-gold" to="/">
                  Contact
                </Link> */}
                                <a
                                    href="#contact-us"
                                    className="hover:text-gold"
                                >
                                    Contact
                                </a>
                            </li>
                            <li>
                                <Link
                                    className="hover:text-gold"
                                    to="/legal-services"
                                >
                                    Services
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <LinkButton
                        className="px-5 py-2 text-sm rounded-[19px] hidden md:flex"
                        to="tel:+448635421349"
                        href="tel:+448635421349"
                    >
                        Contact Us <HiArrowLongRight className="text-xl" />
                    </LinkButton>
                    <span
                        onClick={handleToggleNavOpen}
                        className="text-3xl cursor-pointer md:hidden"
                    >
                        <CgMenuRight />
                    </span>
                </div>
                {/* //*__________________MOBILE NAVBAR_________________ */}
                <div
                    onClick={handleToggleNavOpen}
                    className={`md:hidden text-lg text-light font-medium [line-height:400%;] fixed bg-dark/70 top-1 right-1 left-1 bottom-1 backdrop-blur-md ${navClassName}`}
                >
                    <div className="text-right flex justify-end font-black p-3">
                        <CgClose
                            onClick={handleToggleNavOpen}
                            className="p-2 rounded-full text-5xl bg-red cursor-pointer"
                        />
                    </div>
                    <nav className="flex flex-col gap-6 items-center justify-center h-full text-center">
                        <ul onClick={handleToggleNavOpen}>
                            <li>
                                <Link className="hover:text-gold" to="/">
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link className="hover:text-gold" to="/about">
                                    About Us
                                </Link>
                            </li>
                            <li>
                                {/* <Link className="hover:text-gold" to="/">
                  Contact
                </Link> */}
                                <a
                                    href="#contact-us"
                                    className="hover:text-gold"
                                >
                                    Contact
                                </a>
                            </li>
                            <li>
                                <Link
                                    className="hover:text-gold"
                                    to="/legal-services"
                                >
                                    Services
                                </Link>
                            </li>
                        </ul>
                        <div className="flex gap-4 text-xl items-center">
                            <a
                                className="text-red hover:text-[#3b5998]"
                                href="https://www.facebook.com/abdul.mcmillian"
                            >
                                <FaFacebook />
                            </a>
                            <a
                                className="text-red hover:text-[#00acee]"
                                href="tel:+448635421349"
                            >
                                <FaTwitter />
                            </a>
                            <a
                                className="text-red hover:text-[#0e76a8]"
                                href="tel:+448635421349"
                            >
                                <FaLinkedinIn />
                            </a>
                            <a
                                className="text-red text-2xl hover:text-[#d62976]"
                                href="tel:+448635421349"
                            >
                                <AiFillInstagram />
                            </a>
                        </div>
                    </nav>
                </div>
            </nav>
        </header>
    );
}

export const useNavbar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [navClassName, setNavClassName] = useState("mobile-nav-component");

    const handleToggleNavOpen = () => {
        setIsNavOpen(!isNavOpen);
    };

    useEffect(() => {
        setNavClassName(
            isNavOpen ? "mobile-nav-component-open" : "mobile-nav-component"
        );
    }, [isNavOpen]);

    return { navClassName, handleToggleNavOpen };
};
