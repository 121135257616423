import React, { AnchorHTMLAttributes } from "react";
import { Link } from "react-router-dom";

interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  to: string;
}

const LinkButton: React.FC<AnchorProps> = ({
  className = "",
  children,
  to,
  ...props
}) => {
  return (
    <Link to={to}
      className={`${className} text-center inline-flex items-center justify-center p-3 rounded-full gap-2 hover:opacity-80 hover:gap-3 text-dark font-[500] bg-gold hover:bg-gold`}
      {...props}
    >
      {children}
    </Link>
  );
};

export default LinkButton;