import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IServicesCard } from "../../types/services-card.types";
import { CallUsArea } from "../about";
import ContactFormSection from "../home/ContactFormSection";

export default function Details() {
  const { id } = useParams();
  const [services] = useState<IServicesCard[]>([
    {
      id: 1,
      banner: "/giambrone-global-business-information-transfer-world.jpg",
      title: "International Lawyers",
      sub_description: "Global legal support for businesses and individuals.",
      detailed_description:
        "Founded in 2004, Giambrone has expanded globally with offices in key commercial and cultural centers across Europe. Our international lawyers assist clients in investing or developing in the UK and overseas. We offer legal support to commercial and private clients, providing expertise in setting up businesses in the UK and abroad.",
      benefits_engagements: [
        "Expert legal support for international businesses",
        "Specialized assistance for UK-based businesses expanding overseas",
        "Multidisciplinary approach for comprehensive advice",
        "Trusted reputation earned over time",
        "Tailored solutions for individual and commercial clients",
      ],
      round_up:
        "Count on Giambrone & Partners for global legal expertise and personalized solutions for your international ventures.",
    },
    {
      id: 2,
      banner: "/giambrone-estate-residetial-building.jpg",
      title: "Real Estate and Residential Property",
      sub_description:
        "Expertise in Italian real estate transactions and investments.",
      detailed_description:
        "Giambrone's Italian real estate lawyers bring insight, knowledge, and expertise to residential and commercial property transactions. We have extensive experience in assisting international buyers and investors in acquiring properties across Italy. Our services cover various property matters, including residential, commercial, and industrial properties.",
      benefits_engagements: [
        "Insightful guidance for residential and commercial property transactions",
        "Expertise in Italian real estate regulations and laws",
        "Assistance to international buyers and investors",
        "Multilingual support for seamless communication",
        "Comprehensive services for a hassle-free experience",
        "Tailored solutions for diverse real estate needs",
        "Ability to handle surrounding legal matters associated with property transactions",
      ],
      round_up:
        "Discover exceptional real estate opportunities in Italy with Giambrone & Partners' expert legal support.",
    },
    {
      id: 3,
      banner: "/giambrone-stack-money-coin-with-trading-graph.jpg",
      title: "Banking and Finance",
      sub_description:
        "Technical expertise and strategic counsel for international transactions.",
      detailed_description:
        "Giambrone’s banking and finance lawyers possess strong technical skills, problem-solving capabilities, and a balanced approach to negotiations. We represent Italian and non-Italian lenders, borrowers, private equity funds, government bodies, and more in various international transactions. Our expertise includes transactional banking, real estate finance, syndication, collateral, and more.",
      benefits_engagements: [
        "Expertise in transactional banking and real estate finance",
        "Multinational representation of lenders, borrowers, and investors",
        "Comprehensive advice on banking law and financial services",
        "Proven track record in successful international transactions",
        "Customized solutions for diverse banking needs",
      ],
      round_up:
        "Choose Giambrone Partners for a legacy of excellence spanning over two centuries, providing progressive thinking and unparalleled expertise to navigate complex financial landscapes with confidence.",
    },
    {
      id: 4,
      banner: "/giambrone-glasses-pc-book-and-pen.jpg",
      title: "Litigation and Dispute Resolution",
      sub_description:
        "Strategic and effective resolution for complex disputes.",
      detailed_description:
        "Giambrone & Partners' highly respected team of litigation and dispute resolution lawyers is academically strong and enjoys a reputation for success with respect to contentious matters including high-value disputes. We offer Alternative Dispute Resolution (ADR) methods like arbitration and mediation. Our international presence allows us to handle cross-border disputes with a high success rate.",
      benefits_engagements: [
        "Expertise in complex and high-value disputes",
        "Alternative Dispute Resolution (ADR) solutions",
        "Cross-border litigation capabilities",
        "Tailored advice based on business size and design",
        "Multidisciplinary approach to understand client's needs",
      ],
      round_up:
        "Trust Giambrone & Partners to handle your disputes strategically and achieve the best possible outcome for your business.",
    },
    {
      id: 5,
      banner: "/giambrone-handshake-close-up-executives.jpg",
      title: "Services for Individuals",
      sub_description:
        "Personalized legal support for individuals and families.",
      detailed_description:
        "Giambrone is recognized as a leading firm for private client services. We provide legal advice on estates, tax planning, and family matters. Our clients trust us for practical, reliable, and experienced advice. We offer assistance with family, matrimonial, residential property matters, and provide specialized services for the elderly.",
      benefits_engagements: [
        "Expert advice on estates and private property matters",
        "Comprehensive tax planning and family solutions",
        "Experienced support for major life events",
        "Personalized guidance tailored to client's unique circumstances",
        "Specialized service for the elderly clients",
      ],
      round_up:
        "Rely on Giambrone & Partners for personalized legal services and trusted advice, guiding you through significant life events with ease.",
    },
  ]);
  const service = services.find((service) => service.id === Number(id));

  // SCROLL TO TOP
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      {/* //*____________________________SERVICES TOP BANNER______________________________________________________ */}
      <section className="md:mb-0 mb-6 bg-fixed min-h-[250px] bg-jet flex items-center justify-center flex-col relative">
        <img
          src={service?.banner}
          alt="giambrone"
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
        />
        <div className="absolute top-0 left-0 w-full h-full object-cover z-0 bg-jet/70" />
        <div className="wrapper relative z-10">
          <div className="font-semibold text-3xl text-light [line-height:180%;]">
            <h3>{service?.title}</h3>
            <p className="text-base font-normal">{service?.sub_description}</p>
          </div>
        </div>
      </section>
      {/*//*______________________SERVICES DETAILS AREAS__________________________ */}
      <div className="wrapper flex gap-4 justify-between py-8 px-2">
        <div className="md:w-[65%] w-full">
          {/* //*_______________________HALF PAGE COLUMN DESCRIPTION___________________ */}
          <div className="">
            <div className="flex items-center gap-4 text-2xl text-red font-extrabold">
              <h3>More info on {service?.title}</h3>
            </div>
            <article>
              <p className="text-lg">{service?.detailed_description}</p>
            </article>
          </div>
          <br />
          {/* //*_______________________HALF PAGE COLUMN BENEFITS AND ENGAGEMENTS___________________ */}
          <div className="">
            <div className="flex items-center mb-6 gap-4 text-2xl text-red font-extrabold">
              <h3>Benefits / Engagements</h3>
            </div>
            <div className="">
              <ul className="flex flex-col gap-5 list-dot">
                {service?.benefits_engagements.map((benefit, index) => (
                  <li key={index} className="p-2 font-semibold">
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <br />
          {/* //*_______________________HALF PAGE COLUMN ROUNDUP___________________ */}
          <div className="">
            <p className="text-lg">{service?.round_up}</p>
          </div>
        </div>
        {/* //*____________________PAGE SECOND COLUMN FOR LINKS____________________________//*/}
        <ul className="hidden md:flex flex-col gap-5 text-right justify-self-end">
          {services.map((service) => (
            <li
              key={service.id}
              className="transition duration-[0.000s] border-b-2 border-dark p-2 hover:border-red hover:text-red hover:font-semibold"
            >
              <Link className="block" to={`/legal-services/${service.id}`}>
                {service.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {/*//* ______________________________________________________________CALL US AREA_________________________________________________________________ */}
      <CallUsArea />
      {/* //*______________________________CONTACT FORM________________________________ */}
      <ContactFormSection />
    </section>
  );
}
