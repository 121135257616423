import React from "react";
import logo from "../../assets/images/giambronelogo.png";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebook, FaTwitter, FaLinkedinIn } from "react-icons/fa";

export default function Footer() {
    return (
        <footer className="bg-dark">
            <div className="m-auto w-full flex p-4 flex-col text-center">
                <div className="border-b border-gold flex p-4 flex-col items-center bg-light">
                    <img
                        src={logo}
                        className="w-[10.5rem]"
                        alt="giambrone partners logo"
                    />
                </div>
                <div className="flex p-4 flex-col items-center">
                    <div className="flex gap-4 text-xl items-center">
                        <a
                            className="text-red hover:text-[#3b5998]"
                            href="https://www.facebook.com/abdul.mcmillian"
                        >
                            <FaFacebook />
                        </a>
                        <a
                            className="text-red hover:text-[#00acee]"
                            href="tel:+448635421349"
                        >
                            <FaTwitter />
                        </a>
                        <a
                            className="text-red hover:text-[#0e76a8]"
                            href="tel:+448635421349"
                        >
                            <FaLinkedinIn />
                        </a>
                        <a
                            className="text-red text-2xl hover:text-[#d62976]"
                            href="tel:+448635421349"
                        >
                            <AiFillInstagram />
                        </a>
                    </div>
                </div>
                <div className="text-center border-t p-4 pb-2 border-light text-light">
                    {/* &#x24b8;  */}
                    &copy; Copyright 2023 Giambrone's Partners Law Ltd. All
                    Rights Reserved
                </div>
            </div>
        </footer>
    );
}
