import React, { useState } from "react";
import "../../styles/page/services.css";
import { FaBook, FaQuestion } from "react-icons/fa";
import giambroneMobilePhone from "../../assets/images/giambrone-phone-laptop-branding.png";
import { IServicesCard } from "../../types/services-card.types";
import ServicesCard from "./ServicesCard";
import ContactFormSection from "../home/ContactFormSection";
import { AwardsSection } from "../home/AwardSection";

export default function Services() {
  const [services] = useState<IServicesCard[]>([
    {
      id: 1,
      banner: "/giambrone-global-business-information-transfer-world.jpg",
      title: "International Lawyers",
      sub_description: "Global legal support for businesses and individuals.",
      detailed_description:
        "Founded in 2004, Giambrone has expanded globally with offices in key commercial and cultural centers across Europe. Our international lawyers assist clients in investing or developing in the UK and overseas. We offer legal support to commercial and private clients, providing expertise in setting up businesses in the UK and abroad.",
      benefits_engagements: [
        "Expert legal support for international businesses",
        "Specialized assistance for UK-based businesses expanding overseas",
        "Multidisciplinary approach for comprehensive advice",
        "Trusted reputation earned over time",
        "Tailored solutions for individual and commercial clients",
      ],
      round_up:
        "Count on Giambrone & Partners for global legal expertise and personalized solutions for your international ventures.",
    },
    {
      id: 2,
      banner: "/giambrone-estate-residetial-building.jpg",
      title: "Real Estate and Residential Property",
      sub_description:
        "Expertise in Italian real estate transactions and investments.",
      detailed_description:
        "Giambrone's Italian real estate lawyers bring insight, knowledge, and expertise to residential and commercial property transactions. We have extensive experience in assisting international buyers and investors in acquiring properties across Italy. Our services cover various property matters, including residential, commercial, and industrial properties.",
      benefits_engagements: [
        "Insightful guidance for residential and commercial property transactions",
        "Expertise in Italian real estate regulations and laws",
        "Assistance to international buyers and investors",
        "Multilingual support for seamless communication",
        "Comprehensive services for a hassle-free experience",
        "Tailored solutions for diverse real estate needs",
        "Ability to handle surrounding legal matters associated with property transactions",
      ],
      round_up:
        "Discover exceptional real estate opportunities in Italy with Giambrone & Partners' expert legal support.",
    },
    {
      id: 3,
      banner: "/giambrone-stack-money-coin-with-trading-graph.jpg",
      title: "Banking and Finance",
      sub_description:
        "Technical expertise and strategic counsel for international transactions.",
      detailed_description:
        "Giambrone’s banking and finance lawyers possess strong technical skills, problem-solving capabilities, and a balanced approach to negotiations. We represent Italian and non-Italian lenders, borrowers, private equity funds, government bodies, and more in various international transactions. Our expertise includes transactional banking, real estate finance, syndication, collateral, and more.",
      benefits_engagements: [
        "Expertise in transactional banking and real estate finance",
        "Multinational representation of lenders, borrowers, and investors",
        "Comprehensive advice on banking law and financial services",
        "Proven track record in successful international transactions",
        "Customized solutions for diverse banking needs",
      ],
      round_up:
        "Choose Giambrone Partners for a legacy of excellence spanning over two centuries, providing progressive thinking and unparalleled expertise to navigate complex financial landscapes with confidence.",
    },
    {
      id: 4,
      banner: "/giambrone-glasses-pc-book-and-pen.jpg",
      title: "Litigation and Dispute Resolution",
      sub_description:
        "Strategic and effective resolution for complex disputes.",
      detailed_description:
        "Giambrone & Partners' highly respected team of litigation and dispute resolution lawyers is academically strong and enjoys a reputation for success with respect to contentious matters including high-value disputes. We offer Alternative Dispute Resolution (ADR) methods like arbitration and mediation. Our international presence allows us to handle cross-border disputes with a high success rate.",
      benefits_engagements: [
        "Expertise in complex and high-value disputes",
        "Alternative Dispute Resolution (ADR) solutions",
        "Cross-border litigation capabilities",
        "Tailored advice based on business size and design",
        "Multidisciplinary approach to understand client's needs",
      ],
      round_up:
        "Trust Giambrone & Partners to handle your disputes strategically and achieve the best possible outcome for your business.",
    },
    {
      id: 5,
      banner: "/giambrone-handshake-close-up-executives.jpg",
      title: "Services for Individuals",
      sub_description:
        "Personalized legal support for individuals and families.",
      detailed_description:
        "Giambrone is recognized as a leading firm for private client services. We provide legal advice on estates, tax planning, and family matters. Our clients trust us for practical, reliable, and experienced advice. We offer assistance with family, matrimonial, residential property matters, and provide specialized services for the elderly.",
      benefits_engagements: [
        "Expert advice on estates and private property matters",
        "Comprehensive tax planning and family solutions",
        "Experienced support for major life events",
        "Personalized guidance tailored to client's unique circumstances",
        "Specialized service for the elderly clients",
      ],
      round_up:
        "Rely on Giambrone & Partners for personalized legal services and trusted advice, guiding you through significant life events with ease.",
    },
  ]);
  return (
    <section>
      {/* //*______________________________________________LEGAL SERVICES TOP BANNER______________________________________________________ */}
      <section className="md:mb-0 mb-6 bg-fixed min-h-[250px] bg-law-library bg-left bg-no-repeat bg-cover flex items-center justify-center flex-col">
        <div className="wrapper">
          <div className="font-semibold text-3xl text-light [line-height:180%;]">
            <h3>Legal Services We Provide</h3>
            <p className="text-base font-normal">
              Giambrone Partners Law Ltd: A Legacy of Legal Excellence
            </p>
          </div>
        </div>
      </section>
      {/* //*_____________________________________________LEGAL SERVICES DETAILS_________________________________________________________ */}
      <div className="grid md:grid-cols-4 min-h-[300px] gap-4">
        <div className="xl:col-span-2 text-lg md:col-span-2 md:ml-[12%] m-[2%] gap-6 flex justify-center flex-col">
          <div className="flex items-center gap-4 text-[1.56em] text-red font-extrabold">
            <h3>Services We Offer</h3>
            <FaBook size={30} />
          </div>
          <article>
            At <b>Giambrone Partners Law Ltd</b> we pride ourselves on being an
            award-winning full-service law firm, providing a comprehensive range
            of legal solutions to our valued clients. Our team of dedicated and
            experienced lawyers is committed to delivering excellence in every
            aspect of the legal practice. With a strong international presence
            and a network of offices in major cities across Europe, we offer a
            diverse and wide-ranging scope of legal services to cater to our
            clients' unique needs.
          </article>
        </div>
        <div className="bg-giambrone-laptop-phone xl:ml-auto min-h-[350px] w-full overflow-hidden xl:col-span-2 md:col-span-2 xl:w-[90%]">
          <img
            className="w-full min-h-[350px] max-h-[450px] object-cover xl:w-[90%] xl:m-auto"
            src={giambroneMobilePhone}
            alt="giambrone partners laptop and phone branding"
          />
        </div>
      </div>
      {/* //*__________________________________________ GIAMBRONE SERVICES CARD____________________________________________________//  */}
      <div className="border-y-2 border-dark pb-8">
        <div className="wrapper">
          <div className="text-center my-12 mb-20 font-bold text-xl md:text-4xl flex items-center md:gap-6 justify-center">
            <h1>Our areas of expertise include</h1>
            <FaQuestion className="text-red" />
          </div>
          <div className="grid place-items-center items-center gap-6 md:grid-cols-2">
            {/* _____________CARD___________ */}
            {services.map((service) => {
              return <ServicesCard key={service.id} service={service} />;
            })}
            {/* _____________CARD END___________ */}
          </div>
        </div>
      </div>
      {/* //*______________________________CONTACT FORM________________________________ */}
      <ContactFormSection />
      {/* //*______________________________AWARD SECTION________________________________ */}
      <AwardsSection />
    </section>
  );
}
