import awardTopLawFirm2022 from "../../assets/images/TLAW-RGB-234x300.jpg";
import awardTheLegal500 from "../../assets/images/uk-recommended-lawyer-2023.jpg";
import awardMagicCircle2023 from "../../assets/images/Magic-Circle-2023-Shortlisted-300x198.png";
import awardPcaFinalist from "../../assets/images/PCA22_Finalist_Private_mid-300x75.jpg";

export const AwardsSection = () => {
  return (
    <div className="border-t-2 border-jet">
      <div className="wrapper overflow-x-hidden [white-space:nowrap;] w-[90vw] flex items-center justify-center">
        <div className="min-w-[500px] inline-flex gap-4 overflow-x-auto p-4">
          <div className="bg-light flex justify-center items-center text-white p-4 text-2xl m-6 text-center min-w-[200px]">
            <img
              className="w-36 object-cover"
              src={awardTopLawFirm2022}
              alt="Award Top Law Firm 2022"
            />
          </div>
          <div className="bg-light flex justify-center items-center text-white p-4 text-2xl m-6 text-center min-w-[200px]">
            <img
              className="w-36 object-cover"
              src={awardMagicCircle2023}
              alt="Award Magic Circle 2023"
            />
          </div>
          <div className="bg-light flex justify-center items-center text-white p-4 text-2xl m-6 text-center min-w-[200px]">
            <img
              className="w-36 object-cover"
              src={awardTheLegal500}
              alt="Award The Legal 500"
            />
          </div>
          <div className="bg-light flex justify-center items-center text-white p-4 text-2xl m-6 text-center min-w-[200px]">
            <img
              className="w-[12rem] object-cover"
              src={awardPcaFinalist}
              alt="Award Pca Finalist"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
