import React from "react";
import "../../styles/page/about.css";
import { FaBook, FaLightbulb, FaQuestion } from "react-icons/fa";
import giambroneLaptop from "../../assets/images/giambrone-laptop.png";
import ContactFormSection from "../home/ContactFormSection";

export default function index() {
    return (
        <>
            {/* //*______________________________________________ABOUT US TOP BANNER______________________________________________________ */}
            <section className="md:mb-0 mb-6 bg-fixed min-h-[250px] bg-sky-scrapper bg-right-top bg-no-repeat bg-cover flex items-center justify-center flex-col">
                <div className="wrapper">
                    <div className="font-semibold text-3xl text-light [line-height:180%;]">
                        <h3>About Us</h3>
                        <p className="text-base font-normal">
                            Giambrone Partners Law Ltd: A Legacy of Legal
                            Excellence
                        </p>
                    </div>
                </div>
            </section>
            {/* //*____________________________________________________________________ABOUT US DETAILS_________________________________________________________ */}
            <div className="grid md:grid-cols-4 min-h-[300px] gap-4">
                <div className="xl:col-span-2 text-lg md:col-span-2 md:ml-[12%] m-[2%] gap-6 flex justify-center flex-col">
                    <div className="flex items-center gap-4 text-[1.56em] text-red font-extrabold">
                        <h3>About Giambrone Partners</h3>
                        <FaBook size={30} />
                    </div>
                    <article>
                        <b>Giambrone Partners Law Ltd</b> is a distinguished,
                        award-winning law firm with a legacy spanning over two
                        centuries. We offer full-service legal solutions,
                        specializing in corporate and capital markets, banking
                        and finance, private wealth, dispute resolution, and
                        residential and commercial real estate. Our team of
                        dedicated professionals provides insightful,
                        forward-thinking counsel, helping clients navigate
                        complex legal landscapes with confidence.
                    </article>
                </div>
                <div className="bg-giambrone-laptop xl:ml-auto min-h-[350px] w-full overflow-hidden xl:col-span-2 md:col-span-2 xl:w-[90%]">
                    <img
                        className="w-full min-h-[350px] max-h-[450px] object-cover xl:w-[90%] xl:m-auto"
                        src={giambroneLaptop}
                        alt="giambrone partners laptop"
                    />
                </div>
            </div>
            {/*//* ______________________________________________________________OUR VISION AND WHY US_________________________________________________________________ */}
            <div className="bg-dark md:p-8 p-3 py-8">
                <div className="wrapper gap-16 text-light grid md:grid-cols-2">
                    <div className="gap-6 flex justify-center flex-col">
                        <div className="flex items-center gap-4 text-3xl text-red font-extrabold">
                            <h3>Our Vision</h3>
                            <FaLightbulb />
                        </div>
                        <article>
                            We are committed to building teams of exceptional
                            talent, with our cross-border lawyers possessing the
                            expertise to address diverse client needs. We
                            understand the unique factors impacting cross-border
                            matters and continually expand our jurisdictional
                            capacity to navigate complex legal issues
                            effectively.
                        </article>
                    </div>
                    <div className="gap-6 flex justify-center flex-col">
                        <div className="flex items-center gap-4 text-3xl text-red font-extrabold">
                            <h3>Why Choose Us</h3>
                            <FaQuestion />
                        </div>
                        <article>
                            Our firm combines tradition with progressive
                            thinking, embracing the evolving legal landscape to
                            deliver the highest standards of service. Our
                            unwavering pursuit of excellence sets us apart as a
                            trusted partner for all your legal needs. Choose
                            Giambrone Partners Law Ltd - Your gateway to
                            exceptional legal representation, global expertise,
                            and enduring success.
                        </article>
                    </div>
                </div>
            </div>
            {/*//* ______________________________________________________________CALL US AREA_________________________________________________________________ */}
            <CallUsArea />
            {/*//* ______________________________________________________________CONTACT US_________________________________________________________________ */}
            <ContactFormSection />
        </>
    );
}

export function CallUsArea() {
    return (
        <div className="bg-wavy p-10 bg-no-repeat">
            <div className="wrapper flex items-center justify-center flex-col gap-6">
                <p className="text-xl text-red text-center font-extrabold">
                    To discuss your circumstances, Call Us Today
                </p>
                <a
                    href="tel:+448635421349"
                    className="text-center text-light inline-flex items-center justify-center p-3 rounded-full gap-2 hover:opacity-80 hover:gap-3 font-[500] bg-red hover:bg-red"
                >
                    +44 (208) 181-9675
                </a>
            </div>
        </div>
    );
}
