import React, { useState } from "react";
import "../../styles/page/home.css";
import LinkButton from "../../components/ui/LinkButton";
import { HiArrowLongRight } from "react-icons/hi2";
import { MdAccountBalance } from "react-icons/md";
import { ImHammer2 } from "react-icons/im";
import { GiInjustice } from "react-icons/gi";
import { ImLink } from "react-icons/im";
import { FaQuestion } from "react-icons/fa";
import emailMockup from "../../assets/images/giambrone-partners-law-email-mockup-2.png";
import giambronPartnersLogo from "../../assets/images/giambronelogo.png";
import giambronLawLogo from "../../assets/images/giambronelawlogo.png";
import { CgMail } from "react-icons/cg";
import { IoBusiness } from "react-icons/io5";
import ContactFormSection from "./ContactFormSection";
import ServicesCard from "../services/ServicesCard";
import { IServicesCard } from "../../types/services-card.types";
import { AwardsSection } from "./AwardSection";

const Home = () => {
    const [services] = useState<IServicesCard[]>([
        {
            id: 1,
            banner: "/giambrone-global-business-information-transfer-world.jpg",
            title: "International Lawyers",
            sub_description:
                "Global legal support for businesses and individuals.",
            detailed_description:
                "Founded in 2004, Giambrone has expanded globally with offices in key commercial and cultural centers across Europe. Our international lawyers assist clients in investing or developing in the UK and overseas. We offer legal support to commercial and private clients, providing expertise in setting up businesses in the UK and abroad.",
            benefits_engagements: [
                "Expert legal support for international businesses",
                "Specialized assistance for UK-based businesses expanding overseas",
                "Multidisciplinary approach for comprehensive advice",
                "Trusted reputation earned over time",
                "Tailored solutions for individual and commercial clients",
            ],
            round_up:
                "Count on Giambrone & Partners for global legal expertise and personalized solutions for your international ventures.",
        },
        {
            id: 2,
            banner: "/giambrone-estate-residetial-building.jpg",
            title: "Real Estate and Residential Property",
            sub_description:
                "Expertise in Italian real estate transactions and investments.",
            detailed_description:
                "Giambrone's Italian real estate lawyers bring insight, knowledge, and expertise to residential and commercial property transactions. We have extensive experience in assisting international buyers and investors in acquiring properties across Italy. Our services cover various property matters, including residential, commercial, and industrial properties.",
            benefits_engagements: [
                "Insightful guidance for residential and commercial property transactions",
                "Expertise in Italian real estate regulations and laws",
                "Assistance to international buyers and investors",
                "Multilingual support for seamless communication",
                "Comprehensive services for a hassle-free experience",
                "Tailored solutions for diverse real estate needs",
                "Ability to handle surrounding legal matters associated with property transactions",
            ],
            round_up:
                "Discover exceptional real estate opportunities in Italy with Giambrone & Partners' expert legal support.",
        },
        {
            id: 3,
            banner: "/giambrone-stack-money-coin-with-trading-graph.jpg",
            title: "Banking and Finance",
            sub_description:
                "Technical expertise and strategic counsel for international transactions.",
            detailed_description:
                "Giambrone’s banking and finance lawyers possess strong technical skills, problem-solving capabilities, and a balanced approach to negotiations. We represent Italian and non-Italian lenders, borrowers, private equity funds, government bodies, and more in various international transactions. Our expertise includes transactional banking, real estate finance, syndication, collateral, and more.",
            benefits_engagements: [
                "Expertise in transactional banking and real estate finance",
                "Multinational representation of lenders, borrowers, and investors",
                "Comprehensive advice on banking law and financial services",
                "Proven track record in successful international transactions",
                "Customized solutions for diverse banking needs",
            ],
            round_up:
                "Choose Giambrone Partners for a legacy of excellence spanning over two centuries, providing progressive thinking and unparalleled expertise to navigate complex financial landscapes with confidence.",
        },
        {
            id: 4,
            banner: "/giambrone-glasses-pc-book-and-pen.jpg",
            title: "Litigation and Dispute Resolution",
            sub_description:
                "Strategic and effective resolution for complex disputes.",
            detailed_description:
                "Giambrone & Partners' highly respected team of litigation and dispute resolution lawyers is academically strong and enjoys a reputation for success with respect to contentious matters including high-value disputes. We offer Alternative Dispute Resolution (ADR) methods like arbitration and mediation. Our international presence allows us to handle cross-border disputes with a high success rate.",
            benefits_engagements: [
                "Expertise in complex and high-value disputes",
                "Alternative Dispute Resolution (ADR) solutions",
                "Cross-border litigation capabilities",
                "Tailored advice based on business size and design",
                "Multidisciplinary approach to understand client's needs",
            ],
            round_up:
                "Trust Giambrone & Partners to handle your disputes strategically and achieve the best possible outcome for your business.",
        },
        {
            id: 5,
            banner: "/giambrone-handshake-close-up-executives.jpg",
            title: "Services for Individuals",
            sub_description:
                "Personalized legal support for individuals and families.",
            detailed_description:
                "Giambrone is recognized as a leading firm for private client services. We provide legal advice on estates, tax planning, and family matters. Our clients trust us for practical, reliable, and experienced advice. We offer assistance with family, matrimonial, residential property matters, and provide specialized services for the elderly.",
            benefits_engagements: [
                "Expert advice on estates and private property matters",
                "Comprehensive tax planning and family solutions",
                "Experienced support for major life events",
                "Personalized guidance tailored to client's unique circumstances",
                "Specialized service for the elderly clients",
            ],
            round_up:
                "Rely on Giambrone & Partners for personalized legal services and trusted advice, guiding you through significant life events with ease.",
        },
    ]);

    return (
        <>
            {/* //*_____________________________HERO__________________________ */}
            <div className="bg-image-hero-section h-full [background-size:110%;] bg-no-repeat bg-dark">
                <div className="wrapper">
                    <div className="flex md:px-14 items-center justify-between p-4 py-10 pb-28">
                        <div className="mt-16 text-light">
                            <p className="text-5xl [line-height:110%;] mb-5 font-extrabold max-w-[500px]">
                                We Help You To Solve Your{" "}
                                <span className="text-transparent [-webkit-text-stroke:2px_var(--gold-color);]">
                                    legal Situations.
                                </span>
                            </p>
                            <p className="max-w-[550px] my-4">
                                <b>Giambrone Partners Law Ltd</b> - Champions of
                                Legal Excellence, Resolving Complex Matters
                                Worldwide for our clients, is a leading firm in
                                the field of law.
                            </p>
                            <LinkButton
                                to="tel:+448635421349"
                                href="tel:+448635421349"
                                className="px-16 py-2 text-sm"
                            >
                                Contact Us{" "}
                                <HiArrowLongRight className="text-xl" />
                            </LinkButton>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
            {/* //*_____________________________FEATURE__________________________  */}
            <div className="h-full bg-jet">
                <div className="wrapper">
                    <div className="grid md:grid-cols-3 grid-cols-1 items-center justify-between text-lg font-semibold">
                        <div className="text-light md:border-0 p-4 py-8 flex border-y border-light/50 items-center gap-6 md:justify-center w-full md:pl-0 pl-[25%]">
                            <MdAccountBalance className="text-red text-5xl" />
                            <span>
                                <h1 className="text-red">Truly</h1>
                                <h1>Legal Firm</h1>
                            </span>
                        </div>
                        <div className="text-light p-4 py-8 md:border-x border-light/50 flex items-center gap-6 md:justify-center w-full md:pl-0 pl-[25%]">
                            <ImHammer2 className="text-red text-5xl" />
                            <span>
                                <h1 className="text-red">Making</h1>
                                <h1>Legal Impacts</h1>
                            </span>
                        </div>
                        <div className="text-light md:border-0 p-4 py-8 flex border-y border-light/50 items-center gap-6 md:justify-center w-full md:pl-0 pl-[25%]">
                            <GiInjustice className="text-red  text-5xl" />
                            <span>
                                <h1 className="text-red">Ensuring</h1>
                                <h1>Balanced Justice</h1>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* //* ________________________GIAMBRONNE ADVANTAGES__________________________ */}
            <div className="bg-leaf-grey-scale pt-2 pb-28">
                <div className="wrapper">
                    <div className="text-center my-12 mb-20 font-bold text-lg md:text-4xl flex items-center md:gap-6 justify-center">
                        <h1>Why Giambrone Partners Law Ltd.</h1>
                        <FaQuestion className="text-red" />
                    </div>
                    <div className="grid gap-8 md:gap-0 md:grid-cols-2">
                        <div className="flex items-center overflow-hidden group justify-center md:min-w-[300px]">
                            <img
                                className="clip-path-circle md:min-w-[300px] md:w-1/2 group-hover:scale-110"
                                src={emailMockup}
                                alt="giambrone partners law email mockup"
                            />
                        </div>
                        <div className="md:w-2/3 text-center md:text-left text-base font-medium">
                            <p className="my-8 md:text-left">
                                At Giambrone Partners Law Ltd, we specialize in
                                delivering exceptional legal solutions in
                                corporate and capital markets, banking and
                                finance, private wealth, dispute resolution, and
                                residential and commercial real estate. Our team
                                of dedicated professionals works tirelessly to
                                provide insightful, forward-thinking counsel
                                that helps our clients navigate complex legal
                                landscapes with confidence.
                            </p>
                            <LinkButton
                                className="px-16 py-3 text-sm"
                                to="/about"
                            >
                                Read More <CgMail className="text-xl" />
                            </LinkButton>
                        </div>
                    </div>
                </div>
            </div>
            {/* //*____________________________GIAMBRONE_SERVICES___________________________  */}
            <div className="border-t-4 border-gold mt-0 my-10 pt-12">
                <div className="wrapper">
                    <div className="text-center m-auto mb-12 font-bold text-xl md:text-4xl flex flex-wrap items-center md:gap-6 justify-center md:max-w-none max-w-md">
                        <h1>Giambrone Partners Law. Services</h1>
                        <IoBusiness className="text-red" />
                    </div>
                    <div className="grid place-items-center items-center gap-6 md:grid-cols-2">
                        {/* _____________CARD___________ */}
                        {services.slice(1, 3).map((service) => {
                            return (
                                <ServicesCard
                                    key={service.id}
                                    service={service}
                                />
                            );
                        })}
                        {/* _____________CARD END___________ */}
                    </div>
                </div>
            </div>
            {/* //*______________________________DISPLAY AREA________________________________ */}
            <div className="bg-burgundy p-8 bg-display-area bg-cover bg-center min-h-[300px]">
                <div className="wrapper flex justify-center flex-col items-center gap-8 text-center text-lg  min-h-[300px] text-light font-bold">
                    <p className="[letter-spacing:2px;] font-serif">
                        PROUD PARTNERS OF GIAMBRONE'S LAW
                    </p>
                    <div className="flex items-center gap-10">
                        <a href="/">
                            <img
                                className="hover:[-webkit-filter:drop-shadow(0px_0px_10px_var(--gold-color))]"
                                width={200}
                                src={giambronPartnersLogo}
                                alt="giambrone's partner ltd"
                            />
                        </a>
                        <ImLink className="text-xl" />
                        <a href="https://www.giambronelaw.com">
                            <img
                                className="hover:[box-shadow:0px_0px_15px_5px_var(--red);]"
                                width={200}
                                src={giambronLawLogo}
                                alt="giambrone's partner ltd"
                            />
                        </a>
                    </div>
                    <h3 className="[font-family:'Bebas_Neue'] font-bold md:text-5xl text-3xl [letter-spacing:3px;]">
                        WE ARE GIAMBRONE'S PARTNER LTD
                    </h3>
                    <LinkButton
                        className="px-5 py-2 text-sm rounded-[19px] hidden md:flex"
                        to="tel:+448635421349"
                        href="tel:+448635421349"
                    >
                        Contact Us <HiArrowLongRight className="text-xl" />
                    </LinkButton>
                </div>
            </div>
            {/* //*______________________________SUBSCRIBE TO NEWS LETTER________________________________ */}
            <>
                {/* <div className="bg-jet p-12">
        <div className="wrapper flex-col flex justify-center items-center">
          <div className="mb-8 text-center font-bold text-light">
            <h2 className="text-xl font-bold text-light">
              SUBSCRIBE TO OUR NEWSLETTER
            </h2>
            <small className="text-xs font-serif font-thin">
              we wont ever spam you :)
            </small>
          </div>
          <div className="bg-light rounded-full flex items-center w-[100%] overflow-hidden justify-between max-w-[400px]">
            <input
              type="email"
              placeholder="Enter Your Email Address"
              className="bg-light py-2 px-3 outline-none"
            />
            <Button className="py-3 text-sm">
              <p className="hidden md:inline-block">Subscribe</p>{" "}
              <CgMail size={20} />
            </Button>
          </div>
        </div>
      </div> */}
            </>
            {/* //*______________________________CONTACT FORM________________________________ */}
            <ContactFormSection />
            {/* //*______________________________AWARD SECTION________________________________ */}
            <AwardsSection />
        </>
    );
};

export default Home;
