import React from "react";
import { Link } from "react-router-dom";
import { CgMailForward } from "react-icons/cg";
import { IServicesCard } from "../../types/services-card.types";

type Props = {
  service: IServicesCard;
};

export default function ServicesCard({ service }: Props) {
  return (
    <>
      <div className="group card overflow-hidden max-h-80 max-w-lg w-full shadow-lg flex flex-col rounded-md bg-white">
        <div className="overflow-hidden">
          <img
            className="group-hover:scale-110"
            src={service.banner}
            alt="gimabrone partner mobile"
          />
        </div>
        <div className="p-4 pt-0">
          <Link
            to={`/legal-services/${service.id}`}
            className="flex justify-between items-center text-lg gap-4 font-bold py-2 mb-2 text-red"
          >
            <h2>{service.title}</h2>
            <span>
              <CgMailForward className="text-3xl" />
            </span>
          </Link>
          <p>{service.sub_description}</p>
        </div>
      </div>
    </>
  );
}
